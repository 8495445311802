import { AsyncThunk } from '@reduxjs/toolkit';

type SliceState = {
  loading: string[];
};

export const startLoading = (
  state: SliceState,
  asyncThunk: AsyncThunk<any, any, any>,
) => {
  state.loading.push(asyncThunk.typePrefix);
};

export const stopLoading = (
  state: SliceState,
  asyncThunk: AsyncThunk<any, any, any>,
) => {
  // eslint-disable-next-line no-param-reassign
  state.loading = state.loading.filter((id) => id !== asyncThunk.typePrefix);
};
