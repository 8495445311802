import { atomWithImmer } from 'jotai-immer';
import { createStore, atom } from 'jotai';

export const jotaiStore = createStore();

export const app = atomWithImmer({
  zendesk: false,
  viewport: {},
  browser: {},
  hasInternet: false,
  newVersion: false,
  maintenanceMode: false,
  forbiddenAction: false,
});

export const modals = atomWithImmer({});
export const panels = atomWithImmer({});

export const loader = atom({});

export const useAppLoader = jotaiStore.get(loader)

export const onLoad = ({ id, show }) => {
  jotaiStore.set(loader, prev => ({
    ...prev,
    [id]: { show }
  }))
}



