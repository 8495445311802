import { useMemo } from 'react';
import { useSelector } from 'utils/redux';
import { conf as configEnv } from 'config/env';

const useZendeskLink = (page = '') => {
  const userToken = useSelector(({ env }) => env.userToken);

  const autoLoggedLink = useMemo(
    () =>
      `${configEnv?.api}/influence/faq?token=${userToken}${
        page ? `&page=${page}` : ''
      }`,
    [page, userToken],
  );

  return autoLoggedLink;
};

export default useZendeskLink;
