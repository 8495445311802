import React, { lazy, Suspense } from 'react';
import Overlay from 'kolkit/Overlay';

const LoadedComponent = lazy(() => import('./Details'));

export default () => (
  <Suspense fallback={<Overlay on />}>
    <LoadedComponent />
  </Suspense>
);
