import { createAsyncThunk } from '@reduxjs/toolkit';
import { FullStory } from '@fullstory/browser';
import history from 'config/history';

import { batch } from 'actions/profiles';
import { showModal } from 'actions/ui';
import { RootState } from 'config/store';
import { mapNewProfileToOldOne } from 'mappers/searchEngine';
import { PaginatedResponse } from 'types';
import { getIntl } from 'utils/HOCs/IntlGlobalSingleton';
import JsonFormatter from 'utils/JsonFormatter';
import toast from 'utils/toast';
import { MODAL_ID } from 'components/search/SearchEngine/modules/SavedSearchesModal';
import { getFiltersByNetwork } from 'components/search/SearchEngine/hooks/useFiltersByNetwork';

import * as api from './searchEngine.api';
import type { LaunchSearchResponse, SavedSearch, SearchParams } from './searchEngine.types';
import { mapReduxParamsToApiParams } from './searchEngine.utils';
import { setSort } from '.';

export const launchSearch = createAsyncThunk<
  LaunchSearchResponse,
  void,
  { state: RootState }
>('search/searchEngine/launchSearch', async (param, { getState, dispatch }) => {
  const { terms, sort } = getState().search.searchEngine;
  // Default sort to 'community_count' if no 'terms'
  if (
    terms?.trim()?.length === 0 &&
    (sort === 'latest_post' || sort === 'relevance')
  ) {
    dispatch(setSort({ sort: 'community_count' }));
  }

  const reduxParams = getState().search.searchEngine;
  const params = mapReduxParamsToApiParams(reduxParams, true);

  // For fullstory event, get number of filters by network
  const filtersByNetwork = getFiltersByNetwork(reduxParams);

  FullStory('trackEvent', {
    name: 'SEA-nb-filters',
    properties: {
      profile: Object.keys(filtersByNetwork.profile)?.length,
      instagram: Object.keys(filtersByNetwork.instagram)?.length,
      tiktok: Object.keys(filtersByNetwork.tiktok)?.length,
      youtube: Object.keys(filtersByNetwork.youtube)?.length,
      facebook: Object.keys(filtersByNetwork.facebook)?.length,
      twitter: Object.keys(filtersByNetwork.twitter)?.length,
    },
  });

  // Find out which value was selected when the search was launched.
  FullStory('trackEvent', {
    name: 'SEA-search-type',
    properties: { type: reduxParams.search_scope },
  });

  const response = await api.launchSearch(params);

  try {
    const uncleanedParams = mapReduxParamsToApiParams(reduxParams, false);
    const compressedParams = JsonFormatter.compress(
      JSON.stringify(uncleanedParams),
    );
    const currentParams = new URLSearchParams(history.location.search);
    currentParams.set('q', compressedParams);
    history.push({ search: `?${currentParams?.toString()}` });
  } catch (e) {
    console.error('Unparseanble JSON params from redux: ', e);
  }

  if (!response?.error) {
    if (response.public_profiles?.length === 0 && reduxParams.researchable_element === "profile") {
      FullStory('trackEvent', {
        name: 'SEA-kols-no-results',
        properties: {},
      });
    }
    if (response.contents?.length === 0 && reduxParams.researchable_element === "content") {
      FullStory('trackEvent', {
        name: 'SEA-contents-no-results',
        properties: {},
      });
    }

    if (response.public_profiles?.length > 0) {
      dispatch(
        batch(
          response.public_profiles?.map((profile) =>
            mapNewProfileToOldOne(profile),
          ),
        ),
      );
    }
    return response;
  }

  return Promise.reject(new Error("Failed search"));
});

export const launchSearchStandalone = createAsyncThunk<
  LaunchSearchResponse,
  SearchParams,
  { state: RootState }
>(
  'search/searchEngine/launchSearchStandalone',
  async (params, { dispatch }) => {
    const response = await api.launchSearch(params);

    if (!response?.error) {
      if (response.public_profiles?.length > 0) {
        dispatch(
          batch(
            response.public_profiles?.map((profile) =>
              mapNewProfileToOldOne(profile),
            ),
          ),
        );
      }
      return response as LaunchSearchResponse;
    }

    return {} as LaunchSearchResponse;
  },
);

export const exportCurrentSearch = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('search/searchEngine/exportSearch', async (param, { getState }) => {
  const {
    search: {
      searchEngine: { id: searchId },
    },
  } = getState();

  if (searchId) {
    const intl = getIntl();
    toast(
      intl.formatMessage({
        id: 'engine.actions.export.toast.started',
      }),
      {
        type: 'info',
      },
    );

    const response = await api.exportCurrentSearch({ id: searchId });

    // Get the filename
    let filename = 'search-results.csv';
    const header = response.headers.get('Content-Disposition');
    if (header) {
      const parts = header.split(';');
      filename = parts[1].split('=')[1].replace(/"/g, '');
    }

    // Simulate a link click
    const blob = await response.blob(); // create a new Blob object.
    const url = window.URL.createObjectURL(blob); // create a new object url
    const a = document.createElement('a'); // create a new anchor element
    a.href = url; // set its href to the object URL
    a.download = filename; // set its download attribute to the desired filename.
    a.click(); // programmatically clicking the anchor element to trigger the file download.
    toast(
      intl.formatMessage({
        id: 'engine.actions.export.toast.finished',
      }),
      {
        type: 'success',
      },
    );
  }
});

export const fetchSavedSearches = createAsyncThunk<
  PaginatedResponse<{ researches: SavedSearch[] }>,
  void,
  { state: RootState }
  // @ts-ignore
>('search/searchEngine/fetchSavedSearches', async () => {
  const response = await api.fetchSavedSearches();

  if (response && response.error) {
    return Promise.reject(response);
  }

  return Promise.resolve({ ...response });
});

export const saveSearch = createAsyncThunk<
  void,
  { params: SavedSearch; silent?: boolean; isUpdate?: boolean },
  { state: RootState }
>('search/searchEngine/saveSearch', async ({ params, silent, isUpdate }) => {
  const mappedParams = mapReduxParamsToApiParams(params);

  const saveSearchParams = {
    ...mappedParams,
    saved: true,
    search: null,
    id: params.id,
    name: params.name,
  };
  // INFO: There is no ID if the search has not been launched yet, so launch it with the `saved: true` param.
  const response = await (mappedParams.id
    ? api.saveSearch(saveSearchParams)
    : api.launchSearch(saveSearchParams));

  if (response && !response.error) {
    if (!silent) {
      const intl = getIntl();
      toast(
        intl.formatMessage({
          id: isUpdate
            ? 'savedSearches.toast.updated'
            : 'savedSearches.toast.saved',
        }),
        {
          type: 'success',
          title: intl.formatMessage({ id: 'global.success' }),
        },
      );
    }
    FullStory('trackEvent', {
      name: 'SEA-CTA-save',
      properties: {},
    });
    return Promise.resolve();
  }
  return Promise.reject();
});

export const deleteSearch = createAsyncThunk<
  void,
  SavedSearch,
  { state: RootState }
>('search/searchEngine/deleteSearch', async (params, { dispatch }) => {

  const response = await api.deleteSearch(params.id);

  if (response && !response.error) {
    const intl = getIntl();
    toast(intl.formatMessage({ id: 'savedSearches.toast.deleted' }), {
      type: 'success',
      title: intl.formatMessage({ id: 'global.success' }),
    });

    void dispatch(fetchSavedSearches());
    return Promise.resolve();
  }

  return Promise.reject();
});

export const saveCurrentFilters = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('search/searchEngine/saveCurrentFilters', (_params, { dispatch, getState }) => {
  const params = getState().search.searchEngine;
  void dispatch(
    showModal({
      id: MODAL_ID,
      data: {
        type: 'save',
        search: params,
      },
    }),
  );
});
