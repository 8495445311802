import React, { useState, useEffect } from 'react';
import { createConsumer } from '@rails/actioncable';
import { conf, DEV_DEBUG } from 'config/env';

import { ActionCableContext } from './index';

const DISABLE_WEBSOCKET = DEV_DEBUG; // disable for 8.2 and for DEV_DEBUG, remove `true || ` later

const ActionCableProvider = ({ children }) => {
  // run once, like in a constructor
  const [cable, setCable] = useState(
    () => DISABLE_WEBSOCKET ? null : createConsumer(conf.websocket)
  );

  useEffect(() => {
    /* if (cable?.connection?.disconnected) {
      // TODO: handle actioncable connection failed
    } */
    return () => {
      if (cable) {
        cable.disconnect();
        setCable(null);
      }
    }
  }, [cable]);

  return (
    <ActionCableContext.Provider value={cable}>
      {children}
    </ActionCableContext.Provider>
  );
};

export default ActionCableProvider;
