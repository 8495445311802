/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mapPosts } from 'mappers/posts';
import { startLoading, stopLoading } from 'slices/utils';
import { launchContentSearch } from './contentSearch.actions';

import { SliceState } from './contentSearch.types';

const initialState: SliceState = {
  terms: '',
  search_scope: 'posts',
  sort: 'relevance',
  required_networks: [],

  loading: [],
  posts: [],
  page: 0,
  per_page: 25,
  total_count: -1, // -1 for placeholder; 0 for no result
};

// Reducers
const searchEngineSlice = createSlice({
  name: 'contentSearch',
  initialState,
  reducers: {
    updateKey: (state, action) => {
      state[action.payload.key] = action.payload.data;
    },
    clearAllFilters: () => {
      return {
        ...initialState,
      };
    },
    setTermsAndScope: (
      state,
      action: PayloadAction<{
        terms?: SliceState['terms'];
        search_scope?: SliceState['search_scope'];
      }>,
    ) => {
      state.page = 0;
      if ('terms' in action.payload) {
        state.terms = action.payload.terms || '';
      }
      if ('search_scope' in action.payload) {
        if(state.search_scope !== action.payload.search_scope) {
          // clear results
          state.posts = [];
          state.total_count = -1;
        }
        state.search_scope = action.payload.search_scope || 'posts';
      }
    },
    setSort: (
      state,
      action: PayloadAction<{
        sort: SliceState['sort'];
      }>,
    ) => {
      state.page = 0;
      state.sort = action.payload.sort;
    },
    toggleRequiredNetworks: (
      state,
      action: PayloadAction<{
        network: SliceState['required_networks'][number];
      }>,
    ) => {
      state.page = 0;
      state.required_networks = [action.payload.network];
    },
    clearRequiredNetworks: (state) => {
      state.page = 0;
      state.required_networks = [];
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    // launchSearch
    builder
      .addCase(launchContentSearch.pending, (state) => {
        startLoading(state, launchContentSearch);
      })
      .addCase(launchContentSearch.fulfilled, (state, action) => {
        const payloadPosts =
          action.payload.posts || action.payload.videos || [];
        const newPosts =
          action.payload.page === 0
            ? mapPosts(payloadPosts)
            : [...state.posts, ...mapPosts(payloadPosts)];
        state.posts = newPosts;
        state.page = action.payload.page;
        // Special case for visual_search as there is no total_count so we put a high number
        // Stop when there are no more to load
        state.total_count =
          payloadPosts.length < state.per_page
            ? newPosts.length
            : action.payload.total_count;

        stopLoading(state, launchContentSearch);
      })
      .addCase(launchContentSearch.rejected, (state) => {
        stopLoading(state, launchContentSearch);
      });
  },
});

// action creators
export const { actions } = searchEngineSlice;

export default searchEngineSlice.reducer;
