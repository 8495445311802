import React, { memo, useMemo } from 'react';
import { Badge, badgeClasses } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import Icon from '../Icon';
import Tooltip from '../Tooltip';
import TruncateText from '../TruncateText';

import styles from './Button3.module.scss';

const wrapWithTooltipIfNeeded = (button, tooltip) => {
  if (!tooltip) return button;
  return (
    <Tooltip content={tooltip}>
      <span className={styles.spanWrapper}>{button}</span>
    </Tooltip>
  );
};

const wrapWithBadgeIfNeeded = (button, onClickClose, fullWidth) => {
  if (!onClickClose) return button;
  return (
    <Badge
      badgeContent={
        <Icon
          isButton
          size="medium"
          theme="solid"
          label="times-circle"
          onClick={onClickClose}
        />
      }
      sx={{
        width: fullWidth ? '100%' : 'auto',
        [`& .${badgeClasses.badge}`]: {
          padding: 0,
          top: 6,
          right: 6,
          backgroundColor: 'transparent',
          visibility: 'hidden',
        },
        '&:hover': {
          [`& .${badgeClasses.badge}`]: {
            visibility: 'visible',
          },
        },
      }}
      invisible={!onClickClose}
    >
      {button}
    </Badge>
  );
};

const Button3 = React.forwardRef((
  {
    children,
    size,
    theme,
    icon,
    disabled,
    label,
    type,
    reverse,
    onClick,
    onClickClose,
    counter,
    className,
    iconTheme,
    iconActive,
    iconClassName,
    iconOnClick,
    iconFill,
    iconActiveFill,
    iconSize,
    iconRotate,
    width,
    height,
    to,
    tooltip,
    fullWidth,
    isDropdown,
    active,
    dataId,
    selected,
    style,
    ...rest
  }, ref) => {
  const classNames = cn(
    styles.button,
    styles[size],
    styles[theme],
    {
      [styles.withIcon]: icon && (label || children),
      [styles.reverse]: reverse,
      [styles.onlyIcon]: !children && !label && !counter,
      [styles.fullWidth]: fullWidth && !isDropdown,
      // [styles.isDropdown]: isDropdown,
      [styles.disabled]: disabled,
      [styles.selected]: selected,
      [styles.active]: active
    },
    className
  );

  const buttonStyle = useMemo(
    () =>
      Object.assign(
        {},
        {
          userSelect: 'none',
          pointerEvents: disabled ? 'none' : 'initial',
        },
        style,
        !width ? null : { width },
        !height ? null : { height },
      ),
    [style, width, height, disabled],
  );

  const renderIcon = useMemo(
    () => {
      const cnIcon = cn(styles.icon, iconClassName, {
        [styles.disabled]: disabled,
        [styles.rotate]: iconRotate
      });

      const handleIconClick = e => {
        if (iconOnClick) {
          e.stopPropagation();
          e.persist();
          iconOnClick(e);
        }
      }

      return icon ? (
        <Icon
          label={icon}
          className={cnIcon}
          theme={iconTheme}
          active={iconActive}
          fill={iconFill}
          activeFill={iconActiveFill}
          size={iconSize}
          onClick={handleIconClick}
          dataId={dataId ? `${dataId}-icon` : null}
        />
      ) : null
    },
    [dataId, icon, iconActive, iconTheme, iconFill, iconActiveFill, iconSize, iconRotate, iconClassName, iconOnClick, disabled]
  );

  const hasLink = useMemo(
    () => to
      ? (
        <Link to={to}>
          {children}
        </Link>
      )
      : children,
    [children, to]
  );

  const getLabel = useMemo(
    () => typeof label === 'object'
      ? label
      : (
        <TruncateText>
          {label}
        </TruncateText>
      ),
    [label]
  );

  const button = useMemo(() => {
    return (
      <button
        ref={ref}
        style={buttonStyle}
        disabled={disabled}
        onClick={onClick}
        type={type}
        className={classNames}
        data-id={dataId}
        {...rest}
      >
        {renderIcon}
        {(children || label || counter) && (
          <span className={styles.content}>
            {children && hasLink}
            {label && getLabel}
            {!!counter && <span className={styles.badge}>{counter}</span>}
          </span>
        )}
      </button>
    );
  }, [
    counter,
    ref,
    buttonStyle,
    disabled,
    onClick,
    type,
    classNames,
    dataId,
    rest,
    renderIcon,
    children,
    label,
    hasLink,
    getLabel,
  ]);

  return wrapWithBadgeIfNeeded(
    wrapWithTooltipIfNeeded(button, tooltip),
    onClickClose,
    fullWidth,
  );
});

Button3.displayName = 'Button3';

Button3.propTypes = {
  name: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'tertiaryNoBorder','tertiaryReverse', 'tertiaryNew', 'shopify', 'link']),
  iconTheme: PropTypes.oneOf(['solid', 'thin', 'regular', 'brand']),
  iconSize: PropTypes.oneOf(['huge', 'big', 'medium', 'small']),
  icon: PropTypes.string,
  iconFill: PropTypes.string,
  iconActiveFill: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  label: PropTypes.any,
  disabled: PropTypes.bool,
  iconActive: PropTypes.bool,
  iconRotate: PropTypes.bool,
  type: PropTypes.string,
  reverse: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isDropdown: PropTypes.bool,
  onClick: PropTypes.func,
  onClickClose: PropTypes.func,
  counter: PropTypes.number,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  iconOnClick: PropTypes.func,
  width: PropTypes.string,
  to: PropTypes.string,
  tooltip: PropTypes.string,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  dataId: PropTypes.string
};

Button3.defaultProps = {
  name: '',
  children: null,
  icon: null,
  label: null,
  size: 'medium',
  iconTheme: 'regular',
  iconActive: false,
  iconFill: null,
  iconRotate: false,
  iconActiveFill: null,
  iconSize: 'medium',
  fullWidth: false,
  isDropdown: false,
  theme: 'primary',
  className: null,
  iconClassName: null,
  width: null,
  to: null,
  tooltip: null,
  iconOnClick: null,
  active: false,
  selected: false,
  disabled: false,
  reverse: false,
  type: 'button',
  onClick: () => {},
  onClickClose: undefined,
  counter: null,
  dataId: null
};

export default memo(Button3);
