import { TypedUseSelectorHook, shallowEqual, useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux'
import _isEqual from 'lodash.isequal';

import type { RootState, AppDispatch } from 'config/store'
import { Dispatch, AnyAction } from 'redux';

const useDispatch = ():Dispatch<AnyAction> => useReduxDispatch<AppDispatch>()
const useSelector: TypedUseSelectorHook<RootState> = <TSelected>(selector: (state: RootState) => TSelected) => useReduxSelector(selector, shallowEqual);
const useDeepSelector: TypedUseSelectorHook<RootState> = <TSelected>(selector: (state: RootState) => TSelected) => useReduxSelector(selector, _isEqual);

export { useDispatch, useSelector , useDeepSelector };
