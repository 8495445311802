import React from 'react';
import { Bounce, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Icon } from 'kolkit';

import { notifications } from 'config/app';

import Markdown from 'components/ui/Markdown';

export default (message, options = {}) => {
  const {
    duration: autoClose = 5000,
    title,
    cta,
    type,
    link,
    ...rest
  } = options;

  return toast(
    <div>
      {title && <div className="Toastify-title">{title}</div>}
      <Markdown className="Toastify-message">{message}</Markdown>
      {link && (
        <span>
          <Link to={link.to}>{link.label}</Link>
        </span>
      )}
      {cta}
    </div>,
    {
      autoClose,
      transition: Bounce,
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      className: 'toast',
      icon: notifications[type]
        ? (props) => (
            <Icon
              {...props}
              label={notifications[type]?.icon}
              fill={notifications[type]?.color}
              theme={notifications[type]?.iconTheme}
              size="huge"
            />
          )
        : undefined,
      type,
      ...rest,
    },
  );
};
