import React, { forwardRef, memo, useMemo } from 'react';
import cn from 'classnames';

import { Tooltip } from 'kolkit';
import SvgFromUrl from 'kolkit/SvgFromUrl';

import styles from './Icon.module.scss';

const sizeIcon = {
  tiny: {
    container: 22,
    icon: 10,
  },
  small: {
    container: 22,
    icon: 14,
  },
  medium: {
    container: 32,
    icon: 16,
  },
  big: {
    container: 40,
    icon: 20,
  },
  huge: {
    container: 40,
    icon: 24,
  },
};

type Props = {
  label: string;
  id?: string;
  dataId?: string;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  className?: string;
  width?: number | string; // should only be used to overwrite "size"
  fill?: string;
  style?: React.CSSProperties;
  theme?: 'thin' | 'solid' | 'regular' | 'brand' | 'duotone' | 'navbar' | 'navbarGradient';
  size?: 'huge' | 'big' | 'medium' | 'small' | 'tiny';
  isButton?: boolean;
  active?: boolean;
  hovered?: boolean;
  disabled?: boolean;
  tooltip?: string;
  tooltipPosition?:
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'left-start'
    | 'left'
    | 'left-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-start'
    | 'bottom'
    | 'bottom-end';
  activeFill?: string;
};

const Icon: React.FC<Props> = forwardRef<HTMLSpanElement, Props>(
  (
    {
      id,
      onClick,
      isButton,
      className,
      label = 'info',
      width,
      style,
      theme = 'thin',
      fill = '#0c2329',
      size = 'medium',
      disabled,
      active,
      hovered,
      activeFill = 'va(--color-primary-400)',
      tooltip,
      tooltipPosition = 'top' as const,
      dataId,
      ...rest
    },
    ref,
  ) => {
    const containerStyle = useMemo(() => {
      const initSize =
        !isButton && width
          ? width
          : isButton
          ? sizeIcon[size].container
          : sizeIcon[size].icon;
      return {
        width: initSize,
        height: initSize,
        flex: `0 0 ${initSize}px`,
        ...style,
      };
    }, [isButton, size, width, style]);

    const getBrandColor = useMemo(() => {
      switch (label) {
        case 'instagram-color':
          return '#AC35A9';
        case 'facebook-color':
          return '#1877F2';
        case 'tiktok-color':
          return '#010101';
        case 'twitter-color':
          return '#1DA1F2';
        case 'youtube-color':
          return '#E62117';
        case 'snapchat-color':
          return '#000000';
        default:
          return fill || '';
      }
    }, [fill, label]);

    const IconStyle = useMemo(() => {
      const initSize = !isButton && width ? width : sizeIcon[size].icon;
      return {
        fill: !active ? getBrandColor : activeFill,
        width: initSize,
        height: initSize,
        opacity: disabled ? '0.4' : '1',
      };
    }, [isButton, width, size, active, getBrandColor, activeFill, disabled]);

    const cnIcon = cn(styles.icon, className, {
      [styles.isButton]: isButton,
      [styles.hovered]: hovered,
      [styles.disabled]: disabled,
      [styles.alignComment]:
        label === 'comment-alt' || label === 'comment-alt-plus',
      [styles.alignTag]: label === 'tag',
    });

    const renderIcon = (
      <span
        id={id}
        key={label}
        ref={ref}
        className={cnIcon}
        style={containerStyle}
        onClick={disabled ? undefined : onClick}
        data-id={dataId || id}
        role={onClick ? 'button' : 'img'}
        {...rest}
      >
        <SvgFromUrl
          url={`/svg/${!active ? theme : 'solid'}/${label?.replace(
            '-color',
            '',
          )}.svg`}
          alt={label}
          id={id}
          width={width}
          height={width}
          style={IconStyle}
          disableUniqueIds={theme === 'navbarGradient'}
        />
      </span>
    );

    if (!label) return null;

    return !tooltip ? (
      renderIcon
    ) : (
      <Tooltip key={label} content={tooltip} position={tooltipPosition}>
        {renderIcon}
      </Tooltip>
    );
  },
);

Icon.displayName = 'Icon';

export default memo(Icon);
