import isURL from 'validator/lib/isURL';

export const isValidUrl = url =>
  isURL(url, { protocols: ['http', 'https'], require_protocol: true });

export const getSnaUrlNetworkType = (url = '') => {
  const regex = /^https?:\/\/(?:www\.)?(instagram|tiktok|facebook|youtube|twitter)\.com\/.+$/;
  return regex.exec(url)?.[1] || null;
};

export const isValidSnaUrl = (url = '') => {
  const regex = /^https?:\/\/(?:www\.)?(?:instagram\.com|tiktok\.com|facebook\.com|twitter\.com|youtube\.com\/(?:channel|user|c))\/@?[a-zA-Z0-9._\-±]+\/?\??.*$/;
  const isYoutubeUrl = url.includes('youtube');
  const refusedParams = ['shorts', 'feed', 'studio', 'playlist', 'watch', 'youtu.be.com'];
  let isValidYoutubeUrl = true;

  if (isYoutubeUrl) {
    refusedParams.forEach(param => {
      if (url.includes(param)) isValidYoutubeUrl = false;
      return null;
    })
  }

  return isYoutubeUrl
    ? isValidYoutubeUrl
    : regex.test(url);
};

export const formatSnaUrl = (url = '', force = false) => {
  if (!url?.trim()) return '';
  const trimmed = `https://${url
    ?.replace(/(http:\/\/|https:\/\/|www\.)/gim, '')
    ?.trim()}`;
  const matchRegex = /(^https?:\/\/(?:www\.)?(?:instagram\.com|tiktok\.com|facebook\.com|twitter\.com|youtube\.com)\/@?[a-zA-Z0-9._\-±]+\/?)\??.*$/;
  return force ? trimmed : (matchRegex.exec(trimmed)?.[1] || '');
};
