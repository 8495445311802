import { createReducer } from '@reduxjs/toolkit';
import update from 'immutability-helper';

import * as UI from 'constants/ui';
import * as ENV from 'constants/env';
import * as USER from 'constants/user';

const MODALES = [
  'interactionsFormModale',
  'searchExistingList',
  'campaignModalForm',
  'confirmDeleteList',
  'userSubmitProjectCongratulation',
  'confirmRemoveList',
  'confirmArchiveCampaign',
  'confirmRemoveProfileFromList',
  'adminConfirmPublishList',
  'adminChangeListState',
  'adminUpdateProfilePrice',
  'adminSendProfileEmail',
  'adminUpdateProfileState',
  'apiError',
  'suggest_influencer',
  'usersRolesCampaignModale',
  'addPosts',
  'editLabel',
  'deleteLabel',
  'shareMonitor',
  'previewMessage',
  'favoriteConfirmMultipleActions',
  'reportProfile',
  'reportTheme',
  'editStatuses',
  'editDelivery',
  'askStatsModal',
  'saveTemplateModal',
  'manageTemplateModal',
  'confirmRemoveDraft',
  'select_kols',
];

const intialState = {
	loading: true,
	loader: [] as string[],
	device: {
		type: 'desktop',
		isMobile: false,
		isDesktop: true,
	},
	drawers: {
		filters: false,
		profile: false,
	},
	profileDetails: {
		show: false,
		id: null,
	},
	modales: MODALES.reduce(
	  (p, m) => ({ ...p, [m]: { show: false } }),
	  {},
	) as Record<string, {
		show: boolean,
		data: Record<string, unknown>,
	}>,
};

const reducers = {
	[ENV.CLIENT_TOKEN_SET]: state => update(state, {
		loading: { $set: true }
	}),
	[USER.PARAMS_LOADING]: state => update(state, {
		loading: { $set: true }
	}),
	[USER.PARAMS_LOADED]: state => update(state, {
		loading: { $set: false }
	}),

	[UI.LOADING]: (state, { payload: bool }) => update(state, {
		loading: { $set: bool }
	}),

	[UI.LOADER]: (state, { payload: id }) => {
		const index = state.loader.findIndex(item => item === id);
		if (index !== -1) {
			state.loader.splice(index, 1)
		}
		else {
			state.loader.push(id)
		}
	},

	[UI.CONTROLLED_LOADER]: (state, { payload: { id, show } }) => {
		if (!show) {
			state.loader.splice(state.loader.findIndex(item => item === id), 1)
		} else {
			state.loader.push(id)
		}
	},

	[UI.DEVICE_CHANGED]: (state, { payload: device }) => update(state, {
		device: {
			$set: {
				type: device,
				isMobile: device === "mobile",
				isDesktop: device === "desktop",
			}
		}
	}),

	[UI.MODAL_SHOW]: (state, {
		payload: { id, ...datas }}) => update (state, {
		modales: {
			[id]: {
				$set: {
					show: true,
					...datas,
				}
			}
		}
	}),

	[UI.MODAL_HIDE]: (state, {payload:{id}}) => update (state, {
		modales: {
			[id]: {
				$set: {
					show: false,
					data: {},
				}
			}
		}
	}),
	[UI.MODAL_UPDATE]: (state, {payload:{id, datas}}) => update (state, {
		modales: {
			[id]: {
				$merge: datas
			}
		}
	}),
	[UI.PROFILE_OPEN]: state => update (state, {
		drawers: {
			profile: { $set: true }
		}
	}),
	[UI.PROFILE_CLOSE]: state => update (state, {
		drawers: {
			profile: { $set: false }
		}
	}),
	[UI.FILTERS_OPEN]: state => update(state, {
		drawers: {
			filters: { $set: true }
		}
	}),
	[UI.FILTERS_CLOSE]: state => update(state, {
		drawers: {
			filters: { $set: false }
		}
	}),
}


export default createReducer(intialState, reducers);
