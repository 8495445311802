import { get, put, post, remove } from 'api';
import { ApiPromise } from 'api/types';
import type { PaginatedResponse } from 'types';
import { SavedSearch, SearchParams } from './searchEngine.types';

export const launchSearch = async (params: SearchParams) => {
  return post({
    endpoint: `influence/v2/researches`,
    params,
  });
};

export const fetchSavedSearches: () => ApiPromise<
  PaginatedResponse<{ researches: SavedSearch[] }>
> = async () => {
  return get({
    endpoint: 'influence/v2/researches',
    params: {
      page: 0,
      per_page: 50,
    },
  });
};

export const saveSearch: (
  params: SavedSearch,
) => ApiPromise<SavedSearch> = async (params) => {
  return put({
    endpoint: `influence/v2/researches/${params.id}`,
    params,
  });
};

export const deleteSearch = id => remove({
  endpoint: `influence/v2/researches/${id}`,
  debug: true,
})

export const exportCurrentSearch: (params: {
  id: number;
}) => ApiPromise<Response> = async (params) => {
  return post({
    endpoint: `influence/v2/researches/${params.id}/exports`,
  });
};
