import React from 'react';

export const ActionCableContext = React.createContext(null);

export const CHANNELS = {
  NOTIFICATION: 'NotificationChannel',
  CONVERSATION: 'ConversationChannel',
  INBOX: 'InboxChannel',
  BANNER_MESSAGES: 'BannerMessagesChannel',
  USER: 'UserChannel',
  ASYNC_TASKS: 'AsyncTasksChannel',
  CAMPAIGN: 'CampaignChannel'
};

export { default as ActionCableProvider } from './ActionCableProvider';
