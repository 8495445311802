export const PROFILE_TOGGLES = [
  {
    icon: 'diploma',
    name: 'arpp_certified',
    label: 'engineSearch.filters.attributes.arpp_certified',
    tooltip: 'engineSearch.filters.attributes.arpp_certified.tooltip',
    dataId: 'SEA-profile-ARPP',
  },
  {
    icon: 'envelope',
    name: 'with_an_email',
    label: 'engineSearch.filters.attributes.with_an_email',
    tooltip: 'engineSearch.filters.attributes.with_an_email.tooltip',
    dataId: 'SEA-profile-email',
  },
  {
    icon: 'hashtag',
    name: 'influence_for_good',
    label: 'engineSearch.filters.attributes.influence_for_good',
    tooltip: 'engineSearch.filters.attributes.influence_for_good.tooltip',
    dataId: 'SEA-profile-forgood',
  },
];

export const PROFILE_GENDERS = [
  {
    name: 'male' as const,
    label: 'engine.filters.male',
  },
  {
    name: 'female' as const,
    label: 'engine.filters.female',
  },
  {
    name: 'other' as const,
    label: 'engine.filters.other',
  },
];

export const INSTAGRAM_TOGGLES = [
  {
    icon: 'badge-check',
    name: 'instagram_certified',
    label: 'engineSearch.filters.attributes.account_certified',
    tooltip: 'engineSearch.filters.attributes.instagram_certified.tooltip',
    dataId: 'SEA-instagram-certified',
  },
  {
    icon: 'link',
    name: 'instaconnected',
    label: 'engineSearch.filters.attributes.instaconnected',
    tooltip: 'engineSearch.filters.attributes.instaconnected.tooltip',
    dataId: 'SEA-instagram-instaco',
  },
  {
    icon: 'box-open',
    name: 'seeding',
    label: 'engineSearch.filters.attributes.seeding',
    tooltip: 'engineSearch.filters.attributes.seeding.tooltip',
    dataId: 'SEA-instagram-seeding',
  },
];

export const TIKTOK_TOGGLES = [
  {
    icon: 'badge-check',
    name: 'tiktok_certified',
    label: 'engineSearch.filters.attributes.account_certified',
    tooltip: 'engineSearch.filters.attributes.tiktok_certified.tooltip',
    dataId: 'SEA-tiktok-certified',
  },
];

export const FACEBOOK_TOGGLES = [
  {
    icon: 'badge-check',
    name: 'facebook_certified',
    label: 'engineSearch.filters.attributes.account_certified',
    tooltip: 'engineSearch.filters.attributes.facebook_certified.tooltip',
    dataId: 'SEA-facebook-certified',
  },
];
