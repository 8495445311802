/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_WORKFLOW_NAME } from 'config/campaignFlow';

import {
  getCampaignWorkflows,
  addNewWorkflow,
  updateWorkflow,
  removeWorkflow,
  addStatus,
  deleteStatus,
  updateCampaignWorkflow,
  updateCampaignWorkflowSelection,
} from './campaignWorkflow.actions';
import { SliceState, Status } from './campaignWorkflow.types';

import { startLoading, stopLoading } from '../utils';

const computeWorkflowCustomProperties = (workflow) => {
  return {
    isUsersDefaultTemplate: workflow.name === DEFAULT_WORKFLOW_NAME,
  };
};

const initialState: SliceState = {
  loading: [],
  workflows: {},
  templateToEdit: null,
  current_workflow_id: null,
  selected_workflow_id: null,
  team_statuses: {},
};

// Reducers
const campaignWorkflowSlice = createSlice({
  name: 'campaignWorkflow',
  initialState,
  reducers: {
    updateKey: (state, action) => {
      state[action.payload.key] = action.payload.data;
    },
    addNewStatus: (state, action: PayloadAction<{ status: Status }>) => {
      const { status } = action.payload;
      state.templateToEdit?.status_ids.push(status.id);
    },
    updateStatuses: (
      state,
      action: PayloadAction<{
        status_ids: Array<Status['id']>;
      }>,
    ) => {
      const { status_ids } = action.payload;
      if (state.templateToEdit) {
        state.templateToEdit.status_ids = status_ids;
      }
    },
    /** Update current workdflow's proposition count, Payload contains update count (positive or negative), but not current count to set */
    updateCurrentWorkflowPropositionCount: (
      state,
      action: PayloadAction<Record<string, number>>,
    ) => {
      if (state.current_workflow_id) {
        state.workflows[state.current_workflow_id].proposition_count =
          Object.entries(action.payload).reduce(
            (prev, [statusId, statusCount]) => {
              return {
                ...prev,
                [statusId]: (prev[statusId] || 0) + statusCount,
              };
            },
            state.workflows[state.current_workflow_id].proposition_count || {},
          );
      }
    },
  },
  extraReducers: (builder) => {
    // getCampaignWorkflows
    builder
      .addCase(getCampaignWorkflows.pending, (state) => {
        startLoading(state, getCampaignWorkflows);
      })
      .addCase(getCampaignWorkflows.fulfilled, (state, action) => {
        if (action.payload.workflows) {
          state.workflows = action.payload.workflows.reduce((prev, curr) => {
            return {
              ...prev,
              [curr.id.toString()]: {
                ...curr,
                ...computeWorkflowCustomProperties(curr),
              },
            };
          }, {});
        }
        if (action.payload.current_workflow_id) {
          state.current_workflow_id = action.payload.current_workflow_id;
        }
        state.team_statuses = action.payload.team_statuses.reduce(
          (prev, curr) => {
            return { ...prev, [curr.id]: curr };
          },
          {},
        );
        stopLoading(state, getCampaignWorkflows);
      })
      .addCase(getCampaignWorkflows.rejected, (state) => {
        stopLoading(state, getCampaignWorkflows);
      });
    // Add new workflow
    builder
      .addCase(addNewWorkflow.pending, (state) => {
        startLoading(state, addNewWorkflow);
      })
      .addCase(addNewWorkflow.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.workflows[id] = {
          ...action.payload,
          ...computeWorkflowCustomProperties(action.payload),
        };
        stopLoading(state, addNewWorkflow);
      })
      .addCase(addNewWorkflow.rejected, (state) => {
        stopLoading(state, addNewWorkflow);
      });
    // Remove workflow
    builder
      .addCase(removeWorkflow.pending, (state) => {
        startLoading(state, removeWorkflow);
      })
      .addCase(removeWorkflow.fulfilled, (state, action) => {
        const { id } = action.payload;
        delete state.workflows[id];
        stopLoading(state, removeWorkflow);
      })
      .addCase(removeWorkflow.rejected, (state) => {
        stopLoading(state, removeWorkflow);
      });
    // Update workflow
    builder
      .addCase(updateWorkflow.pending, (state) => {
        startLoading(state, updateWorkflow);
      })
      .addCase(updateWorkflow.fulfilled, (state, action) => {
        const { id } = action.payload.workflow;
        state.workflows[id] = {
          ...action.payload.workflow,
          ...computeWorkflowCustomProperties(action.payload.workflow),
        };
        stopLoading(state, updateWorkflow);
      })
      .addCase(updateWorkflow.rejected, (state) => {
        stopLoading(state, updateWorkflow);
      });
    // Add Status
    builder
      .addCase(addStatus.pending, (state) => {
        startLoading(state, addStatus);
      })
      .addCase(addStatus.fulfilled, (state, action) => {
        const status = action.payload;
        // Add it to the list of status
        state.team_statuses = {
          ...state.team_statuses,
          [status.id]: status,
        };

        // Make it active by default
        if (state.templateToEdit)
          state.templateToEdit.status_ids.push(status.id);
        stopLoading(state, addStatus);
      })
      .addCase(addStatus.rejected, (state) => {
        stopLoading(state, addStatus);
      });
    // Add Status
    builder
      .addCase(deleteStatus.pending, (state) => {
        startLoading(state, deleteStatus);
      })
      .addCase(deleteStatus.fulfilled, (state, action) => {
        const statusId = action.payload;
        delete state.team_statuses[statusId];
        stopLoading(state, deleteStatus);
      })
      .addCase(deleteStatus.rejected, (state) => {
        stopLoading(state, deleteStatus);
      });
    // Update workflow assigned to a campaign
    builder
      .addCase(updateCampaignWorkflow.pending, (state) => {
        startLoading(state, updateCampaignWorkflow);
      })
      .addCase(updateCampaignWorkflow.fulfilled, (state) => {
        stopLoading(state, updateCampaignWorkflow);
      })
      .addCase(updateCampaignWorkflow.rejected, (state) => {
        stopLoading(state, updateCampaignWorkflow);
      });
    builder.addCase(updateCampaignWorkflowSelection, (state, action) => {
      state.selected_workflow_id = action.payload;
    });
  },
});

// action creators
export const { actions } = campaignWorkflowSlice;

export default campaignWorkflowSlice.reducer;
