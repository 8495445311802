import { FullStory, isInitialized } from '@fullstory/browser';
import { appsignal } from 'components/MainContainer';

const logError = (error: Error, args?: Record<string, string | number>) => {
  return appsignal.sendError(error, (span) => {
    let fullstorySessionURL = '';
    if (isInitialized()) {
      FullStory('log', {
        level: 'error',
        msg: `${error.name}:\n ${error.message}`,
      });
      fullstorySessionURL = FullStory('getSession', { format: 'url.now' });
    }

    span.setTags({
      fullstorySessionURL,
      currentUrl: window.location.href,
      ...args,
    });
  });
};

export default logError;
