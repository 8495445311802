export const FACEBOOK = 'facebook';
export const TWITTER = 'twitter';
export const INSTAGRAM = 'instagram';
export const INSTAGRAM_STORY = 'instagramStories'; // from API
export const INSTAGRAM_REEL = 'instagramReels'; // from API
export const INSTAGRAM_IGTV = 'instagramIGTV';
export const TIK_TOK = 'tiktok';
export const YOUTUBE = 'youtube';
export const SNAPCHAT = 'snapchat';
export const SNAPCHAT_SAVED_STORIES = 'snapchatSavedStories';
export const SNAPCHAT_SPOTLIGHTS = 'snapchatSpotlights';

// Ordered by importance
export const NETWORKS = [
  INSTAGRAM,
  TIK_TOK,
  YOUTUBE,
  FACEBOOK,
  TWITTER,
  SNAPCHAT,
] as const;

export const DELIVERY_NETWORKS = [
  {
    network: INSTAGRAM,
    key: INSTAGRAM,
    mediaType: 'post',
    dataIdKey: 'post',
  },
  {
    network: INSTAGRAM,
    key: INSTAGRAM_STORY,
    mediaType: 'story',
    isStory: true,
    dataIdKey: 'story',
  },
  {
    network: INSTAGRAM,
    key: INSTAGRAM_REEL,
    mediaType: 'reel',
    isReel: true,
    dataIdKey: 'reel',
  },
  {
    network: YOUTUBE,
    mediaType: 'video',
    key: YOUTUBE,
    dataIdKey: 'youtube',
  },
  {
    network: TIK_TOK,
    mediaType: 'video',
    key: TIK_TOK,
    dataIdKey: 'tiktok',
  },
  {
    network: FACEBOOK,
    mediaType: 'post',
    key: FACEBOOK,
    dataIdKey: 'facebook',
  },
  {
    network: TWITTER,
    mediaType: 'tweet',
    key: TWITTER,
    dataIdKey: 'twitter',
  },
  {
    network: SNAPCHAT,
    mediaType: 'saved story',
    key: SNAPCHAT_SAVED_STORIES,
    dataIdKey: 'savedStory',
  },
  {
    network: SNAPCHAT,
    mediaType: 'spotlight',
    key: SNAPCHAT_SPOTLIGHTS,
    dataIdKey: 'spotlight',
  },
];

export const COLORS = {
  instagram: '#D300C5',
  facebook: '#1877F2',
  tiktok: '#010101',
  twitter: '#1B9DF0',
  youtube: '#FF0000',
  snapchat: '#000000',
};