import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'config/store';

import * as api from './contentSearch.api';
import type { ContentSearchResponse } from './contentSearch.types';
import { mapReduxParamsToApiParams } from './contentSearch.utils';

export const launchContentSearch = createAsyncThunk<
  ContentSearchResponse,
  void,
  { state: RootState }
>('search/contentSearch/launchContentSearch', async (param, { getState }) => {
  const { terms } = getState().search.contentSearch;
  if (!terms?.trim()?.length) {
    return Promise.reject();
  }

  const reduxParams = getState().search.contentSearch;
  const params = mapReduxParamsToApiParams(reduxParams);

  let response;

  if (params.search_scope === 'audio_content') {
    response = api.launchAudioSearch(params);
  } else {
    response = await api.launchContentSearch(params);
  }

  if (!response?.error) {
    return response;
  }
  return Promise.reject(response);
});
