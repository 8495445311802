import * as featureNames from 'constants/feature-names';
import { isFeatureActive } from 'utils/feature-toggle';

const routes = {
  profile: 'profiles',
  myInfluencers: `my-influencers`, // old one, should redirect to myKOLs
  myKOLs: `my-kols`,
  manage: `manage`,
  list: `list`,
  customFields: `custom-fields`,
  follow: `follow`,
  favorites: `favorites`,
  taggings: `taggings`,
  campaigns: `projects`,
  messaging: 'messaging',
  user: `user`,
  myAccount: `account`,
  myAccountInfos: 'infos',
  myAccount_integrations: 'integrations',
  myAccount_organization: 'organization',
  manageLabels: 'manage-tags',
  dataImport: 'data-import',
  reporting: `monitor`,
  publicReport: `p/report`,
  publicCasting: 'p/castings',
  messengerProfile: `profile`,
  workflow: 'workflow',
  comparison: 'compare',
  login: 'login',
  register: 'register',
  debug: 'debug',
  discountCode: 'discount-code',
  trackingLinks: 'tracking-links',
  dashboard: 'dashboard',
  listening: 'listening',
  followersIncrease: 'followers-increase',
  search: 'search',
  zendesk: 'zendesk',
  contentSearch: 'content-search',
  roi: 'roi',
};

export const getRedirectUrl = () => {
  switch (true) {
    case isFeatureActive(featureNames.SEARCH_ENGINE):
      return '/';
    case isFeatureActive(featureNames.MANAGE_CAMPAIGN):
      return `/${routes.campaigns}`;
    case isFeatureActive({
      any: [featureNames.FAVORITES, featureNames.LABELS],
    }):
      return `/${routes.myKOLs}`;
    case isFeatureActive(featureNames.LISTENING):
      return `/${routes.listening}`;
    default:
      return `/${routes.myAccount}`;
  }
};

export default routes;
