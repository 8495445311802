import { useIntl } from 'react-intl-phraseapp';

// 'intl' service singleton reference
let intl;

// Getter function to expose the read-only 'intl' service
export function getIntl() {
  return intl;
}

const IntlGlobalSingleton = () => {
  intl = useIntl(); // Keep the 'intl' service reference
  return null;
};

export default IntlGlobalSingleton;
