import React, { memo, useMemo } from 'react';
import { useTransition, animated } from 'react-spring';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './Loader.module.scss';

const Loader = ({
  className,
  title,
  titleClassName,
  full,
  fullScreen,
  shadow,
  padding,
  background,
  theme,
  position,
  on,
  controlled,
  style,
  ...rest
}) => {
  const transitions = useTransition(on, null, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  const cnLoader = cn(styles.loader, styles[theme], className, {
    [styles.full]: full,
    [styles.fullWindow]: fullScreen,
    [styles.shadow]: shadow,
    [styles.withTitle]: title
  });

  const cnContainer = cn(styles.container, styles[theme], styles[position], {
    [styles.withTitle]: title
  });

  const cnTitle = cn(styles.title, titleClassName);

  const displayTitle = useMemo(
    () => title && (
      <span className={cnTitle}>
        {title}
      </span>
    ),
    [title, cnTitle]
  );

  const styleContainer = useMemo(
    () => ({ background, ...style }),
    [background, style]
  );

  const styleLoader = useMemo(
    () => ({ padding }),
    [padding]
  );

  const loader = useMemo(
    () => (
      <div {...rest} className={cnLoader} style={styleContainer}>
        <div className={cnContainer} style={styleLoader}>
          <span /><span /><span />
        </div>
        {displayTitle}
      </div>
    ),
    [displayTitle, cnLoader, cnContainer, styleLoader, styleContainer, rest]
  );

  return controlled ? (
    transitions.map(({ item, key, props }) => (
      item && (
        <animated.div key={key} style={props}>
          {loader}
        </animated.div>
      ))
    )
  ) : loader
};

Loader.displayName = 'Loader';

Loader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  theme: PropTypes.oneOf(['default', 'light', 'navy']),
  position: PropTypes.oneOf(['top', 'center']),
  full: PropTypes.bool,
  fullScreen: PropTypes.bool,
  on: PropTypes.bool,
  controlled: PropTypes.bool,
  background: PropTypes.string,
  shadow: PropTypes.bool,
  padding: PropTypes.number
};

Loader.defaultProps = {
  className: null,
  title: null,
  titleClassName: null,
  full: false,
  fullScreen: false,
  on: true,
  position: 'center',
  theme: 'default',
  shadow: false,
  controlled: false,
  padding: null,
  background: 'rgba(255, 255, 255, 1)'
}

export default memo(Loader);
