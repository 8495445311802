import { useTheme } from '@mui/material';
import React, { useState, useLayoutEffect } from 'react';

import useModal from 'utils/hooks/useModal';

const renderModal = (modalID: string, ModalComponent: React.ComponentType) => {
  return Object.assign(
    props => {
      const {
        transitions: {
          duration: { leavingScreen },
        },
      } = useTheme();

      const { show } = useModal(modalID);
      const [shoudBeMounted, setShoudBeMounted] = useState(false);

      useLayoutEffect(() => {
        if (show) {
          setShoudBeMounted(true);
        } else if (shoudBeMounted) {
          setTimeout(() => setShoudBeMounted(false), leavingScreen);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [show]);

      return shoudBeMounted ? <ModalComponent {...props} /> : null;
    },
    { displayName: ModalComponent.displayName || 'renderModalHOC' },
  );
};

export default renderModal;
