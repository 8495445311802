import { post } from 'api';
import { SearchParams } from './contentSearch.types';

export const launchContentSearch = async (params: SearchParams) => {
  return post({
    endpoint: `influence/v2/researches/posts`,
    params,
  });
};

export const launchAudioSearch = async (params: SearchParams) => {
  return post({
    endpoint: `influence/v2/researches/videos`,
    params,
  });
};
