export const CAMPAIGN_STATES = {
	DRAFT: 'draft' as const,
	READY: 'ready' as const,
	IN_PROGRESS: 'started' as const,
	COMPLETED: 'finished' as const,
	ARCHIVED: 'archived' as const,
};

export const CAMPAIGN_STATES_LIST = Object.values(CAMPAIGN_STATES);

export const STATES_WITHOUT_ARCHIVED = CAMPAIGN_STATES_LIST.filter(s => s !== CAMPAIGN_STATES.ARCHIVED);

export const CAMPAIGN_STATES_COLOR: { [char: string]: string } = {
	draft: '#F1E8FE',
	ready: '#FEE7EE',
	started: '#D6F8F1',
	finished: '#ACE9FE',
	archived: '#F1F5F9',
};

export const PROPOSITION = {
	INITIALIZED: 'initialized',
	CONTACTED: 'contacted',
	VALIDATED: 'validated',
	REFUSED: 'refused',
	SIGNED: `signed`,
	CANCELED: 'canceled',
};

export const STATUSES_PHRASE_PREFIX=`campaigns.statuses`;

export const STATUSES_PHRASE_NO_STATUS_KEY=`${STATUSES_PHRASE_PREFIX}.noStatus`;
export const STATUSES_PHRASE_ALL_STATUS_KEY = `${STATUSES_PHRASE_PREFIX}.all`;

export const CASTING_VALIDATION_KEYS = [
  'pending_approval',
  'approved',
  'refused',
] as const;

export const CONTENT_VALIDATION_KEYS = [
  'content_instructions',
  'content_approval',
];

export const STATUS_SHIPPING_KEYS = [
  'prepare_shipment',
  'product_shipped',
  'product_delivered',
] as const;

export const STATUS_PUBLICATION_KEYS = ['published'];

export const CASTING_TAGS: Record<(typeof CASTING_VALIDATION_KEYS)[number], string> = {
  pending_approval: 'CA-casting-pending',
  approved: 'CA-casting-approved',
  refused: 'CA-casting-rejected',
};

export const USER_ROLES = {
	OWNER: 'owner',
	ADMINBC: 'adminbc',
	EDITOR: 'editor',
	VIEWER: 'viewer',
	NONE: 'peon',
	GUEST: 'guest',
}

export const REACHABILITY_QUALITY = {
	GOOD: 'good',
	AVERAGE: 'average',
	BAD: 'bad',
	UNKNOWN: 'unknown',
}

