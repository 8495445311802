/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as api from 'api/kols';
import { RootState } from 'config/store';

import { launchSearch } from './search/searchEngine.slice';

/* type Core = {
  id: number | null;
  first_name: string;
  last_name: string;
  full_name: string;
  pseudo?: string;
  gender: 'male' | 'female' | 'other';
  picture_url: string;
  is_favorite: boolean;
  has_note: boolean;
  audience_credibility_score: number;
  is_stat_sharing_active: boolean;
  has_community_access: boolean;
  influence_for_good: boolean;
  themes?: string[];
  activities: string[];
  is_contactable: boolean;
  contactable_email: string;
  primary_social_account_id: number | null;
}

type SliceState = {
  core: Core,
}; */

// const initialKolState: SliceState = {
const initialKolState = {
  core: {
    id: null,
    first_name: '',
    last_name: '',
    full_name: '',
    pseudo: '',
    gender: '',
    picture_url: '',
    is_favorite: false,
    has_note: false,
    is_stat_sharing_active: false,
    has_community_access: false,
    influence_for_good: false,
    themes: [],
    activities: [],
    is_contactable: false,
    contactable_email: '',
    primary_social_account_id: null,
  },
  details: {
    id: 977916,
    first_name: '',
    last_name: '',
    full_name: '',
    pseudo: '',
    gender: '',
    picture_url: '',
    contactable_email: '',
    is_contactable: false,
  },
  snas: [
    {
      id: null,
      username: '',
      label: '',
      concealed: false,
      inactive: false,
      spammer: false,
      community_count: 0,
      avg_views_count: null,
      avg_engagement_rate_per_post: 0,
    },
  ],
};

type Profile = typeof initialKolState;

const initialState = {
  loading: false,
};

export const loadDataKol = createAsyncThunk<
  { response: Profile; kolId: number },
  { kolId: number; scopes: string },
  { state: RootState }
>('kols/loadDataKol', async ({ kolId, scopes }) => {
  const response = await api.loadDataKol({ kolId, scopes });
  return { response, kolId };
});

const kolsSlice = createSlice({
  name: 'kols',
  initialState,
  reducers: {
    loadDataKols: (
      state,
      { payload }: PayloadAction<Record<number, Profile>>,
    ) => {
      Object.entries(payload).forEach(([kolId, data]) => {
        state[kolId] = {
          ...initialKolState,
          ...state?.[kolId],
          ...data,
        };
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadDataKol.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadDataKol.fulfilled, (state, { payload }) => {
      state[payload.kolId] = {
        ...state?.[payload.kolId],
        ...payload.response,
      };
      state.loading = false;
    });

    // Populate data after search
    builder.addCase(launchSearch.fulfilled, (state, { payload }) => {
      payload.public_profiles?.forEach((profileData) => {
        state[profileData.core.id] = {
          ...initialKolState,
          ...state[profileData.core.id],
          ...profileData,
        };
      });
    });
  },
});

// action creators
export const { loadDataKols } = kolsSlice.actions;

export default kolsSlice.reducer;
