import JSONCrush from 'jsoncrush';

const compress = (json: string) => {
  return JSONCrush.crush(json);
};

const decompress = (compressed: string) => {
  return JSONCrush.uncrush(compressed);
};

export default { compress, decompress };
