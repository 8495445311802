import { conf, env, ENV_PROD } from 'config/env';
import { getStore } from 'config/store';

const isNotProd = env !== ENV_PROD;

export const screenview = screen => {
	if (isNotProd) {
		console.log("[LOG screenview]", screen);
	}
};

export const planhatTraker = async ({ action, info }) => {
	const { user } = getStore();

	if (!conf?.planhat || isNotProd) return null;

	await fetch(`https://analytics.planhat.com/analytics/${conf.planhat}`, {
		method: 'POST',
		headers: {
			'Content-type': 'application/json; charset=UTF-8'
		},
		body: JSON.stringify({
			name: user?.profile?.firstName + user?.profile?.lastName,
			externalId: user?.profile?.id,
			action,
			info: {
				email: user?.profile?.email,
				...info
			},
		}),
	}).then((data) => data);

	return true
}