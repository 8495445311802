import { useCallback } from 'react';
import { setControlledLoader } from 'actions/ui';
import { useDispatch, useSelector } from 'utils/redux';


const useLoading = id => {
  const dispatch = useDispatch();

  const { loading } = useSelector(
    ({ ui }) => ({
      loading: ui.loader.includes(id),
    })
  );

  const on = useCallback(
    () => dispatch(setControlledLoader({ id, show: true })),
    [dispatch, id]
  );

  const off = useCallback(
    () => dispatch(setControlledLoader({ id, show: false })),
    [dispatch, id]
  );

  return { on, off, loading };
};

export default useLoading;
