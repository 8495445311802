import { useEffect, useState } from 'react';

const useWindowSizeObserver = () => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })

  const debounce = (fn, ms) => {
    let timer;
    return () => {
      clearTimeout(timer);
      // eslint-disable-next-line
      timer = setTimeout(() => {
        timer = null;
        fn.apply(this);
      }, ms)
    };
  }

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 500);
    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  return dimensions;
};

export default useWindowSizeObserver;
