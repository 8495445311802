import {useMemo} from 'react';
import routes from 'config/routes';
import { useLocation } from 'react-router-dom';

const useViews = () => {
  const { pathname } = useLocation();

  // Can be on search/campaign/listening/...
  const isProfilePage = useMemo(
    () => pathname.includes(`/${routes.profile}/`),
    [pathname]
  );

  const isSearchPage = useMemo(
    () => pathname === '/',
    [pathname]
  );

  const isCampaignPage = useMemo(
    () => pathname.indexOf(`/${routes.campaigns}`) === 0 || pathname.indexOf(`/${routes.folders}`) === 0,
    [pathname]
  );

  const isComparisonPage = useMemo(
    () => pathname.includes(`/${routes.comparison}`),
    [pathname]
  );

  const isTrackingLinksPage = useMemo(
    () => pathname.includes(`/${routes.trackingLinks}`),
    [pathname]
  );

  const isDiscountCodePage = useMemo(
    () => pathname.includes(`/${routes.discountCode}`),
    [pathname]
  );

  const isReportingPage = useMemo(
    () => pathname.includes(`/${routes.reporting}`),
    [pathname]
  );

  const isMyInfluencersPage = useMemo(
    () => pathname.indexOf(`/${routes.myKOLs}`) === 0,
    [pathname]
  );

  const isMessagingPage = useMemo(
    () => pathname.indexOf(`/${routes.messaging}`) === 0,
    [pathname]
  );

  const isListeningPage = useMemo(
    () => pathname.indexOf(`/${routes.listening}`) === 0,
    [pathname]
  );

  const isDashboardPage = useMemo(
    () => pathname.indexOf(`/${routes.dashboard}`) === 0,
    [pathname]
  );

  const isListeningDetailsPage = useMemo(
    () => {
      const regex = new RegExp(`/${routes.listening}/[0-9]+`);
      return regex.test(pathname);
    },
    [pathname]
  );

  return {
    isSearchPage,
    isProfilePage,
    isCampaignPage,
    isCampaignComparisonPage: isCampaignPage && isComparisonPage,
    isCampaignTrackingLinksPage: isCampaignPage && isTrackingLinksPage,
    isCampaignDiscountCodesPage: isCampaignPage && isDiscountCodePage,
    isCampaignMessagingPage: isCampaignPage && isMessagingPage,
    isCampaignMonitorPage: isCampaignPage && isReportingPage,
    isMyInfluencersPage,
    isMessagingPage,
    isListeningPage,
    isDashboardPage,
    isListeningDetailsPage,
  };
};

export default useViews;
