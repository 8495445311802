import {
  AUDIENCE_TYPES,
  AUDIENCE_TYPES_SNAPCHAT,
  AUDIENCE_TYPES_YOUTUBE,
} from 'constants/profiles';

export const getAudienceTypeByNetwork = (network: string) => {
  switch (network) {
    case 'youtube':
      return AUDIENCE_TYPES_YOUTUBE;
    case 'snapchat':
      return AUDIENCE_TYPES_SNAPCHAT;
    default:
      return AUDIENCE_TYPES;
  }
};

export const getDefaultAudienceTypeByNetwork = (network: string) => {
  return getAudienceTypeByNetwork(network)[0];
};
