import { createAction } from '@reduxjs/toolkit';

import { setControlledLoader } from 'actions/ui'
import * as viewsActions from 'actions/views';
import { updateProfilesToLists } from 'actions/profiles';
import * as api from 'api/profile';
import * as apiProfiles from 'api/profiles';
import * as mappers from 'mappers/profile';
import * as PROFILE from 'constants/profile';

import { launchSearchStandalone } from 'slices/search/searchEngine.slice';
import { onLoad } from 'state/app';


const loadDataProfile = createAction(PROFILE.LOAD_CORE_PROFILE);
export const update = createAction(PROFILE.UPDATE);



/*
 Header
 */

export const getProfileCore = kolId => async dispatch => {
  dispatch(setControlledLoader({ id: 'profileCore', show: true }));
  onLoad({ id: 'profileCore',  show: true });

  let data;

  try {
    data = await api.loadProfileCore(kolId);
    const mappedData = mappers.mappedProfile.fromApi(data);
    dispatch(loadDataProfile(mappedData))
  } catch(e) {
    console.error('getProfileCore', e);
  }

  dispatch(setControlledLoader({ id: 'profileCore', show: false }));

  return data;
}


/*
 Performance TAB
 */

export const getTabPerformance = kolId => async (dispatch) => {
  dispatch(setControlledLoader({ id: 'performanceTab', show: true }));

  try {
    const data = await api.loadTabPerformance(kolId);
    await dispatch(loadDataProfile({ ...data }))
  } catch(e) {
    await Promise.reject(e);
  }

  dispatch(setControlledLoader({ id: 'performanceTab', show: false }));

  return true;
}

export const getTabPerformanceBySna = ({ snaId, kolId, network }) => async (dispatch) => {
  dispatch(setControlledLoader({ id: 'performanceTab', show: true }));

  try {
    const data = await api.loadTabPerformanceSna({ snaId, kolId });
    const topMentions = await dispatch(getTopMentions({ network }));
    await dispatch(loadDataProfile({
      ...data,
      topMentions,
    }))
  } catch(e) {
    await Promise.reject(e);
  }

  dispatch(setControlledLoader({ id: 'performanceTab', show: false }));

  return true;
}

const PERIODS = {
  lastMonth: '1m',
  last3Months: '3m',
  last6Months: '6m',
};

export const getBrandCollaborations = ({ profileId, snaId, period }) => async (dispatch, getState) => {
  dispatch(setControlledLoader({ id: 'getBrandCollaborations', show: true }));

  const differenceInCalendarDays = (
    await import('date-fns/differenceInCalendarDays')
  ).default;

  const {
    page,
    per_page,
    max_content_per_period: previous_max_content_per_period,
    brands: previouslyLoaded,
  } = getState()?.profile?.brand_collaborations;

  const brand_collaborations = await api.getBrandCollaborations({
    profileId,
    snaId,
    period: PERIODS[period],
    page,
    per_page,
  });

  const new_brand_collaborations =
    page === 1
      ? brand_collaborations
      : {
        ...brand_collaborations,
        // Keep track of the max value, it may decrease because of pagination, but keep the max
        max_content_per_period: Math.max(
          brand_collaborations.max_content_per_period,
          previous_max_content_per_period,
        ),
        brands: [...previouslyLoaded, ...brand_collaborations.brands],
      };

  if (new_brand_collaborations.date_labels?.length > 0) {
    // Compute total number of days
    const totalDurationInDays =
      Math.abs(
        differenceInCalendarDays(
          new Date(new_brand_collaborations.date_labels[0].from),
          new Date(
            new_brand_collaborations.date_labels[
            new_brand_collaborations.date_labels?.length - 1
              ].to,
          ),
        ),
      ) + 1;

    // Computer number of days for each period, compared to total
    new_brand_collaborations.date_labels =
      new_brand_collaborations.date_labels.map((label) => {
        const periodDurationInDays =
          Math.abs(
            differenceInCalendarDays(new Date(label.from), new Date(label.to)),
          ) + 1;
        return {
          ...label,
          // This is used in UI for setting the graduation in the table
          widthPercentage: (periodDurationInDays / totalDurationInDays) * 100,
        };
      });
  }

  if (brand_collaborations && !brand_collaborations.error) {
    dispatch(
      update({
        key: 'brand_collaborations',
        data: new_brand_collaborations,
      }),
    );
  }

  dispatch(setControlledLoader({ id: 'getBrandCollaborations', show: false }));

  return true;
};

export const getTopMentions = ({ period, network }) => async (dispatch, getState) => {
  const { views: { profileDetails } } = getState();

  dispatch(setControlledLoader({ id: 'topMentions', show: true }));

  try {
    const res = await api.getTopMentions({
      kolId: profileDetails.id,
      filters: {
        network: network || 'all',
        period: period === 'lastMonth'
          ? '1m'
          : period === 'last6Months'
            ? '6m'
            : 'all',
      }
    });

    dispatch(viewsActions.updateKey({
      view: 'profileDetails',
      key: 'topMentionsFilters',
      period,
      network
    }))

    dispatch(update({
      key: 'topMentions',
      value: res,
    }))
  } catch (e) {
    return console.error('Error fetching getTopMentions: ', e);
  }

  dispatch(setControlledLoader({ id: 'topMentions', show: false }));

  return true
};


/*
 Content TAB
 */

export const loadProfileTopHashtags = kolId => async dispatch => {
  dispatch(setControlledLoader({ id: 'loadTopHashtags', show: true }));

  try {
    const value = await api.loadProfileTopHashtags(kolId);
    dispatch(update({
      key: 'top_hashtags',
      value
    }))
  } catch(e) {
    await Promise.reject(e);
  }

  dispatch(setControlledLoader({ id: 'loadTopHashtags', show: false }));

  return true;
}

export const loadProfilePosts = () => async (dispatch, getState) => {
  const { id, contentFilters } = getState().views.profileDetails;

  dispatch(setControlledLoader({ id: 'loadProfilePosts', show: true }));

  try {
    const response = await dispatch(
      launchSearchStandalone({
        profile_ids: [Number(id)],
        content_types: contentFilters.types,
        researchable_element: 'content',
        terms: contentFilters.search || '',
        search_scope: 'content',
        sort: contentFilters.sortBy,
        required_networks: contentFilters.selectedSnas || [],
        from: contentFilters.date.from,
        to: contentFilters.date.to,
        page: contentFilters.page || 0,
        per_page: 12,
        global_statistics:
          !!contentFilters.search && contentFilters?.search?.trim()?.length > 0, // to load stats
        // Not needed for content search, set to default
        network_filters_operator: 'or',
        basic_filters: [],
        profile_genders: [],
        excluded_list_ids: [],
        excluded_tag_ids: [],
        exclude_favorites: false,
        profile_filters: {},
        instagram_filters: {},
        youtube_filters: {},
        tiktok_filters: {},
        twitter_filters: {},
        facebook_filters: {},
        snapchat_filters: {},
      }),
    );

    dispatch(viewsActions.update({
      view: 'profileDetails',
      statistics_by_account: response.payload?.statistics_by_account || null,
    }))

    dispatch(update({
      key: 'posts',
      value: {
        contents: response?.payload?.contents || [],
        total: response?.payload?.total_count || 0,
      }
    }))
  } catch(e) {
    await Promise.reject(e);
  }

  dispatch(setControlledLoader({ id: 'loadProfilePosts', show: false }));

  return true
}




/*
 Collaboration TAB
 */

export const getTabCollaboration = kolId => async (dispatch, getState) => {
  const { user: { features, profile } } = getState();

  dispatch(setControlledLoader({ id: 'collaborations', show: true }));

  await dispatch(getTags(kolId));

  if (!profile.admin) {
    await dispatch(getKolLists(kolId));
  }

  if (features?.manageCampaigns && !profile.admin) {
    await dispatch(getCollaborationDetails(kolId));
    await dispatch(getProfileCustomFields(kolId));
  }

  dispatch(setControlledLoader({ id: 'collaborations', show: false }));

  return true
}

export const getTags = kolId => async dispatch => {
  try {
    const res = await api.getTags(kolId);
    dispatch(update({
      key: 'tags',
      value: res
    }))
  } catch (e) {
    await Promise.reject(e);
  }

  return true
};

export const updateProfileTags = ({ idsToAdd, idsToRemove, kolIds }) => async dispatch => {
  try {
    if (idsToAdd?.length) {
      await api.updateTags({ profile_ids: kolIds, tag_ids: idsToAdd })
    }
    if (idsToRemove?.length) {
      await api.removeTags({ profile_ids: kolIds, tag_ids: idsToRemove })
    }
    dispatch(getTags(kolIds))
  } catch (e) {
    await Promise.reject(e);
  }

  return true;
}

export const getCollaborationDetails = kolId => async dispatch => {
  try {
    const data = await api.getCollaborationDetails(kolId);
    dispatch(update({
      key: 'collaboration',
      data,
    }))
  } catch (e) {
    await Promise.reject(e);
  }

  return true
}

export const getKolLists = kolId => async dispatch => {
  try {
    const response = await api.getProfileLists(kolId);
    dispatch(update({
      key: 'list_ids',
      value: response.list_ids,
    }))
  } catch (e) {
    await Promise.reject(e);
  }

  return true
}

export const updateProfileLists = ({ idsToAdd, idsToRemove, kolIds, checkedOptions }) => async dispatch => {
  dispatch(setControlledLoader({ id: 'updateProfilesToLists', show: true }));

  try {
    if (idsToAdd?.length) {
      await apiProfiles.addProfilesToLists({ listIds: idsToAdd, profileIds: kolIds })
    }
    if (idsToRemove?.length) {
      await apiProfiles.removeProfilesToLists({ listIds: idsToRemove, profileIds: kolIds })
    }

    if (idsToAdd?.length) {
      await dispatch(updateProfilesToLists({
        profileIds: [kolIds].flat(),
        listIds: checkedOptions,
        action: 'add',
      }));
    }

    dispatch(update({
      key: 'list_ids',
      value: checkedOptions,
    }))
  } catch (e) {
    await Promise.reject(e);
  }

  dispatch(setControlledLoader({ id: 'updateProfilesToLists', show: false }));
}

export const getProfileCustomFields = kolId => async dispatch => {
  try {
    const value = await api.getProfileCustomFields(kolId);
    dispatch(update({
      key: 'fields',
      value,
    }))
  } catch (e) {
    await Promise.reject(e);
  }

  return true
}

export const deleteProfileCustomFields = ({ fieldIds, kolId }) => async () => {
  try {
    await api.removeProfileCustomFields(({ fieldIds, kolId }));
  } catch (e) {
    await Promise.reject(e);
  }
  return true
}

export const updateProfileInformations = (kolId, data) => async dispatch => {
  dispatch(setControlledLoader({ id: 'collaborations', show: true }));

  try {
    const res = await apiProfiles.updateProfileDetails(kolId, data);
    dispatch(update({
      key: 'collaboration',
      data: res,
    }));
  } catch (e) {
    await Promise.reject(e)
  }

  dispatch(setControlledLoader({ id: 'collaborations', show: false }));

  return true
}

