import { combineReducers } from 'redux';

import organization from 'slices/organization.slice';
import kols from 'slices/kols.slice';
import searchEngine from 'slices/search/searchEngine.slice';
import contentSearch from 'slices/search/contentSearch.slice';
import campaignsWorkflow from 'slices/campaignWorkflow.slice';

import affilae from './affilae';
import campaigns from './campaigns';
import campaignsFolders from './campaignsFolders';
import engine from './engine';
import env from './env';
import messaging from './messaging';
import profile from './profile';
import profiles from './profiles';
import ui from './ui';
import user from './user';
import views from './views';
import listeningV2 from './listeningV2';
import followersIncrease from './followersIncrease';
import myInfluencers from './myInfluencers';
import shopify from './shopify';

const reducers = combineReducers({
  env,
  ui,
  user,
  views,
  engine,
  profile,
  profiles,
  campaigns,
  campaignsFolders,
  campaignsWorkflow,
  messaging,
  affilae,
  listeningV2,
  followersIncrease,
  myInfluencers,
  shopify,
  organization,
  search: combineReducers({
    searchEngine,
    contentSearch,
  }),
  kols,
});

export default reducers;
