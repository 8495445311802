import React, { forwardRef, memo, useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes, { string } from 'prop-types';
import useMeasure from 'react-use-measure';
import cn from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';

import parseLocaleNumber from '../utils/parseLocaleNumber';

import HeightTransition from '../utils/HeightTransition';
import Icon from '../Icon';
import Truncate from '../Truncate';

import styles from './Input2.module.scss';

const Input2 = forwardRef((props, ref) => {
  const {
    id,
    dataId,
    className,
    type,
    placeholder,
    name,
    value,
    required,
    onChange,
    onBlur,
    onFocus,
    icon,
    iconFill,
    onClickIcon,
    size,
    theme,
    error,
    errorMessage,
    errorRequiredMessage,
    locale,
    prefixMask,
    suffixMask,
    fullWidth,
    onKeyPress,
    onEnterPress,
    maxLength,
    autoComplete,
    valid,
    forceError,
    resetStyle,
    search,
    nbCaracters,
    noIcon,
    isLoading,
    resetCallback,
    ...rest
  } = props;

  const numberFormatter = Intl.NumberFormat(locale, {
    maximumFractionDigits: 2
  });

  const [focus, setFocus] = useState(false);
  const [hasBeenFocused, setHasBeenFocused] = useState(false);
  const [formattedValue, setFormattedValue] = useState('');
  const [length, setLength] = useState(value?.length || 0);

  const [refPrefixMeasure, prefixMeasure] = useMeasure({ polyfill: ResizeObserver });
  const [refSuffixMeasure, suffixMeasure] = useMeasure({ polyfill: ResizeObserver });
  const [refNbCaractersMeasure, nbCaractersMeasure] = useMeasure({ polyfill: ResizeObserver });

  // Manage Error
  const hasError = hasBeenFocused && !focus && error;
  const hasForceError = !focus && forceError;
  const hasRequiredError = error || (hasBeenFocused && required && !focus && !value);
  const handleDisplayError = hasError || hasRequiredError || hasForceError;

  const cnContainer = cn(styles.container, styles[size], styles[theme], className, {
    [styles.fullWidth]: fullWidth,
    [styles.error]: handleDisplayError,
    [styles.reset]: resetStyle,
    [styles.search]: search,
    [styles.valid]: valid,
    [styles.withNumberCaracs]: nbCaracters,
  });

  const handleChange = useCallback(
    (e) => {
      e.persist();
      const val = e?.target?.value;
      if (nbCaracters) setLength(val?.length);
      if (onChange) {
        let newValue = val;
        let newFormattedValue = val?.toString();

        if (type === 'number') {
          const {
            value: parsedNumber,
            thousandSeparator,
            decimalSeparator
          } = parseLocaleNumber(newValue, locale);
          const isNumberParsable = !Number.isNaN(Number(parsedNumber));

          if (!isNumberParsable) {
            return null;
          }
          if (newValue === '') {
            newFormattedValue = '';
          } else {
            newValue = parsedNumber.toString();
            newFormattedValue =
              newFormattedValue.endsWith(thousandSeparator) ||
              newFormattedValue.endsWith(decimalSeparator)
                ? newFormattedValue
                : numberFormatter.format(newValue);
          }
        }

        setFormattedValue(prefixMask + newFormattedValue + suffixMask);
        return onChange({
          value: newValue,
          name: e?.target?.name,
          id,
          event: e
        });
      }
    },
    [
      id,
      onChange,
      nbCaracters,
      prefixMask,
      suffixMask,
      locale,
      numberFormatter,
      type
    ]
  );

  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') onEnterPress(e);
      if (onKeyPress && e.key !== 'Enter') onKeyPress(e);
    },
    [onEnterPress, onKeyPress]
  )

  const handleFocus = useCallback(
    async e => {
      e.persist();
      await setFocus(true);
      await setHasBeenFocused(true);
      onFocus({ event: e });
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    e => {
      e.persist();
      if (e.currentTarget.contains(e.relatedTarget)) return;
      setFocus(false);
      onBlur({ event: e });
    },
    [onBlur]
  );

  const renderSearchIcon = useMemo(
    () => {
      if (search) {
        return (
          <Icon
            className={cn(styles.searchIcon, styles[theme])}
            label="search"
            width={16}
            fill="#0c2329"
            theme="regular"
          />
        )
      }
      return null;
    },
    [search, theme]
  );

  const resetInput = useCallback(
    () => {
      setFormattedValue('');
      setLength(0);
      if (onChange) {
        onChange({
          value: '',
          name,
          id,
        });
      }
      if (ref) ref?.current?.focus();
      if (resetCallback) resetCallback()
    },
    [onChange, resetCallback, setFormattedValue, setLength, id, name, ref]
  );

  const renderIcon = useMemo(
    () => {
      if ((icon || (value && onChange)) && !noIcon && !rest.disabled) {
        const cnIcon = cn(styles.icon, { [styles.loading]: isLoading });
        const label = isLoading ? 'circle-notch' : (icon || value && 'times');

        if (!label) return null;

        return (
          <Icon
            className={cnIcon}
            label={label}
            width={size === 'large' ? 20 : 16}
            fill={iconFill}
            theme="regular"
            isButton={value ? !isLoading : !!onClickIcon}
            onClick={onClickIcon || resetInput}
            dataId={`${dataId}-icon`}
          />
        );
      }
      return null;
    },
    [
      icon,
      value,
      noIcon,
      rest.disabled,
      onChange,
      isLoading,
      size,
      iconFill,
      onClickIcon,
      dataId,
      resetInput
    ]
  );

  const renderErrorMessage = useMemo(
    () => (
      <div className={styles.helper} style={{width: `calc(100% - ${nbCaractersMeasure?.width + 16}px)`}}>
        <HeightTransition
          config={{
            tension: 340,
            friction: 26,
            mass: 1,
            precision: 1
          }}
          on={handleDisplayError && (errorMessage || errorRequiredMessage)}
        >
          <div className={styles.wrapper}>
            <Icon label="exclamation-circle" size="small" theme="solid" />
            <span className={styles.message}>
              <Truncate>
                {errorMessage || errorRequiredMessage}
              </Truncate>
            </span>
          </div>
        </HeightTransition>
      </div>
    ),
    [handleDisplayError, errorMessage, errorRequiredMessage, nbCaractersMeasure]
  );

  const renderNbCaracters = useMemo(
    () => {
      return (
        nbCaracters && (
          <span className={styles.nbCaracters} ref={refNbCaractersMeasure}>
            {maxLength ? `${length}/${maxLength}` : length || ''}
          </span>
        )
      );
    },
    [nbCaracters, maxLength, length, refNbCaractersMeasure]
  );

  const getValue = useMemo(
    () => {
      if (type === 'number') return formattedValue;
      return value
    },
    [type, value, formattedValue]
  );

  const style = useMemo(
    () => {
      if (prefixMask && suffixMask) {
        return {
          textAlign: 'left',
          paddingLeft: prefixMeasure?.width + 16,
          paddingRight: suffixMeasure?.width + 16
        }
      }
      if (prefixMask) {
        return {
          textAlign: 'left',
          paddingLeft: prefixMeasure?.width + 16
        }
      }
      if (suffixMask) {
        return {
          textAlign: 'right',
          paddingRight: suffixMeasure?.width + 16
        }
      }
    },
    [prefixMask, suffixMask, prefixMeasure, suffixMeasure]
  );

  const renderPrefixMask = useMemo(
    () => prefixMask && (
      <div ref={refPrefixMeasure} className={cn(styles.mask, styles.prefix)}>
        {prefixMask}
      </div>
    ),
    [prefixMask, refPrefixMeasure]
  );

  const renderSuffixMask = useMemo(
    () => suffixMask && (
      <div ref={refSuffixMeasure} className={cn(styles.mask, styles.suffix)}>
        {suffixMask}
      </div>
    ),
    [suffixMask, refSuffixMeasure]
  );

  useEffect(() => {
    if (type === 'number') {
      let newlyFormattedValue = '';
      if (value && (typeof value === 'number' || typeof value === 'string')) {
        const { value: parsedNumber } = parseLocaleNumber(value, locale);
        newlyFormattedValue = Number.isNaN(parsedNumber)
          ? ''
          : numberFormatter.format(value);
      }
      if (newlyFormattedValue !== formattedValue) {
        setFormattedValue(newlyFormattedValue);
      }
    }
  }, [formattedValue, locale, numberFormatter, type, value]);

  const cnInput = cn(styles.input, { [styles.filled]: !!getValue });

  return (
    <div className={cnContainer} {...rest}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div className={styles.content} tabIndex="0" onBlur={handleBlur} >
        {renderSearchIcon}
        {renderPrefixMask}
        <input
          {...rest}
          data-id={dataId || id}
          ref={ref}
          id={id}
          placeholder={placeholder}
          className={cnInput}
          type={type === 'number' ? 'text' : type}
          name={name}
          value={getValue}
          onChange={handleChange}
          onFocus={handleFocus}
          required={required}
          onKeyDown={handleKeyPress}
          maxLength={maxLength}
          autoComplete={autoComplete}
          style={style}
        />
        {!suffixMask && renderIcon}
        {renderSuffixMask}
        {renderNbCaracters}
      </div>
      {renderErrorMessage}
    </div>
  );
});

Input2.displayName = 'Input2';

Input2.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  dataId: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.string,
  iconFill: PropTypes.string,
  onClickIcon: PropTypes.func,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  resetStyle: PropTypes.bool,
  locale: string,
  errorMessage: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'email',
    'password',
    'number',
    'tel',
    'search',
    'hidden'
  ]),
  size: PropTypes.oneOf([
    'small',
    'medium',
    'large'
  ]),
  theme: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'tone',
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  valid: PropTypes.bool,
  error: PropTypes.bool,
  forceError: PropTypes.bool,
  noIcon: PropTypes.bool,
  resetCallback: PropTypes.func,
  onKeyPress: PropTypes.func,
  onEnterPress: PropTypes.func,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  nbCaracters: PropTypes.bool,
  maxLength: PropTypes.number,
  prefixMask: PropTypes.string,
  suffixMask: PropTypes.string
};

Input2.defaultProps = {
  onChange: null,
  id: undefined,
  dataId: null,
  className: undefined,
  icon: null,
  iconFill: "#0c2329",
  onClickIcon: null,
  isLoading: false,
  name: null,
  type: 'text',
  value: undefined,
  size: 'medium',
  theme: 'primary',
  valid: false,
  fullWidth: false,
  disabled: false,
  resetStyle: false,
  required: false,
  locale: undefined,
  error: false,
  forceError: false,
  noIcon: false,
  autoComplete: 'off',
  errorMessage: null,
  onKeyPress: () => {},
  onEnterPress: () => {},
  onFocus: () => {},
  onBlur: () => {},
  resetCallback: () => {},
  nbCaracters: false,
  maxLength: undefined,
  prefixMask: '',
  suffixMask: ''
};

export default memo(Input2);
