import { useEffect, useState, useCallback } from 'react';
import getLocalStorageItem from 'utils/getLocalStorageItem';

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    return getLocalStorageItem(key, initialValue);
  });

  const setValue = useCallback(
    (value) => {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));

        // Dispatch a custom event to notify all instance of this hooks about local storage change
        const event = new CustomEvent('onLocalStorageChanged', {
          detail: {
            key,
            newValue: value,
          },
        });
        document.dispatchEvent(event);
      } catch (error) {
        console.error(error);
      }
    },
    [key],
  );
  useEffect(() => {
    // listen to custom event
    const eventListener = document.addEventListener(
      'onLocalStorageChanged',
      (event) => {
        // If the change is about the key I'm listening to
        if (event.detail.key === key) {
          setStoredValue(event.detail.newValue);
        }
      },
    );

    return () => {
      document.removeEventListener('onLocalStorageChanged', eventListener);
    };
  }, [key]);

  return [storedValue, setValue];
};

export default useLocalStorage;
