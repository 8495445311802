import React, { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import memoize from 'memoizee';

import { getStore } from 'config/store';
import { useDeepSelector } from 'utils/redux';

export const getActiveFeatures = memoize(() => {
  return getStore()?.user?.features?.featuresList || [];
});

export const isFeatureActive = memoize((featureName, options) => {
  if (options?.onlyForKolsquare) return getStore().user.isFromKolsquare;
  if (options?.onlyForCompanyZZZ) return getStore().user.profile.company?.id === 2320; // Id of team "ZZZ test" on prod/staging/lab

  if (typeof featureName === 'string') {
    return getActiveFeatures().includes(featureName);
  }
  const { any, every } = featureName || {};
  if (any && any?.length) {
    return any.some(feature => isFeatureActive(feature, options));
  }
  if (every && every?.length) {
    return every.every(feature => isFeatureActive(feature, options));
  }
  return false;
});

export const decideValueByFeature = memoize(
  ({ featureName, onlyForKolsquare, value, fallback = null }) => {
    if (isFeatureActive(featureName, { onlyForKolsquare })) return value;
    return fallback;
  },
);

export const MountRouteByFeature = ({
  featureName,
  force = false,
  onlyForKolsquare = false,
  onlyForCompanyZZZ = false,
  whiteList = [],
  redirectPath,
}) => {
  const userId = useDeepSelector(({ user }) => user.profile.id);

  if (force || isFeatureActive(featureName, { onlyForKolsquare, onlyForCompanyZZZ }) || whiteList.includes(Number(userId))) return <Outlet />;

  return <Navigate to={redirectPath || '/'} />;
};

export const MountComponentByFeature = ({
  featureName,
  force = false,
  onlyForKolsquare = false,
  onlyForCompanyZZZ = false,
  whiteList = [],
  children,
  fallback = null,
}) => {
  const userId = useDeepSelector(({ user }) => user.profile.id);

  return (
    <Suspense>{
      force || isFeatureActive(featureName, { onlyForKolsquare, onlyForCompanyZZZ }) || whiteList.includes(Number(userId))
        ? children
        : fallback
    }</Suspense>
  );
};
