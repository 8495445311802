import React, { memo } from 'react';
import cn from 'classnames';
import { animated, useTransition } from 'react-spring';

import Loader from 'kolkit/Loader';

import styles from './Overlay.module.scss';

const Overlay = ({ on, className }) => {
  const transitions = useTransition(on, null, {
    unique: true,
    from: { opacity: 0.5 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      mass: 1,
      tension: 320,
      friction: 28
    }
  });

  const cnOverlay = cn(styles.overlay, className);

  return transitions.map(({ item, key, props }) => (
    item ? (
      <animated.div
        key={key}
        className={cnOverlay}
        style={props}
      >
        <Loader full />
      </animated.div>
    ): null
  ))
};

export default memo(Overlay);
