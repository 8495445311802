import type { SearchParams } from './contentSearch.types';

export const mapReduxParamsToApiParams = (
  params: SearchParams,
): SearchParams => {
  const { id, terms, search_scope, sort, page, per_page, required_networks } =
    params;

  // To only send necessary parameters
  return {
    scopes: 'posts',
    id,
    terms,
    search_scope,
    sort,
    page,
    per_page,
    required_networks,
  };
};
