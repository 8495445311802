import { actions } from './contentSearch.reducers';

// Types
export type {
  SliceState,
} from './contentSearch.types';

// Actions
export const {
  updateKey,
  clearAllFilters,
  setTermsAndScope,
  setSort,
  toggleRequiredNetworks,
  clearRequiredNetworks,
  setPage,
} = actions;

export {
  // Thunk
  launchContentSearch,
} from './contentSearch.actions';

export { mapReduxParamsToApiParams } from './contentSearch.utils';

export { default } from './contentSearch.reducers';
