import { get, put, post, remove } from 'api';
import { ApiPromise } from 'api/types';

import {
  Status,
  WithoutId,
  Workflow,
  WorkflowEdition,
} from './campaignWorkflow.types';

export const getCampaignWorkflows: (params: {
  project_id?: number;
}) => ApiPromise<{
  workflows: Array<Workflow>;
  current_workflow_id: number;
  team_statuses: Array<Status>;
}> = ({ project_id }) =>
  get({
    endpoint: `influence/v2/project_workflows${
      project_id ? `?project_id=${project_id}` : ''
    }`,
    catchErrors: [404],
  });

export const addNewWorkflow: (
  params: WithoutId<WorkflowEdition>,
  project_id: number,
) => ApiPromise<Workflow> = (workflow, project_id) =>
  post({
    endpoint: `influence/v2/project_workflows`,
    params: {
      name: workflow.name,
      status_ids: workflow.status_ids,
      project_id,
    },
    catchErrors: [],
  });

export const updateWorkflow: (
  params: WorkflowEdition,
  project_id: number,
) => ApiPromise<Workflow> = ({ id, ...workflowData }, project_id) =>
  put({
    endpoint: `influence/v2/project_workflows/${id}`,
    params: {
      name: workflowData.name,
      status_ids: workflowData.status_ids,
      project_id,
    },
    catchErrors: [],
  });

export const removeWorkflow: (id: number) => ApiPromise<Workflow> = (id) =>
  remove({
    endpoint: `influence/v2/project_workflows/${id}`,
    catchErrors: [404],
  });

/**
 * Status
 */

export const addStatus = (status) =>
  post({
    endpoint: `influence/statuses`,
    params: status,
    catchErrors: [422],
  });

export const deleteStatus = (id) =>
  remove({
    endpoint: `influence/statuses/${id}`,
    debug: true,
  });
